import React from "react";
import Header from "./components/Header";
import Features from "./components/Features";
import Services from "./components/Services";
import Footer from "./components/footer";
function App() {
  return (
    <div className="App">
      <Header/>

      <Features/>
            {/* <div className="f-heading"> */}
        <h1 className="hello">Gallery</h1>
        <p className="hello1">Offering a gorgeous lawn, RajYou Palace, takes away all your open-air celebration worries.The wedding lawn offers ease of commute to your guests. It covers decor and catering services for your big day. Book RajYog Palace, today to make your celebrations one-of-a-kind.</p>
  
      {/* </div> */}
      <Services/>
      <Footer/>
    </div>
  );
}

export default App;


