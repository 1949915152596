import React from 'react';
import Box from './Box';
import image1 from '../images/s1.jpeg'
import image2 from '../images/s2.jpeg'
import image3 from '../images/s6.jpeg'
import image4 from '../images/s7.jpeg'
import image5 from '../images/s5.jpeg'
import image6 from '../images/s8.jpeg'
import image7 from '../images/gallery1.jpg'
import image8 from '../images/gallery2.jpg'
import image9 from '../images/gallery3.jpg'
import image10 from '../images/gallery4.jpg'
// import image11 from '../images/gallery7.jpg'
// import image12 from '../images/gallery6.jpg'

function Services(){
    return (
        <div id='feature1'>
            <div className="f-heading">
            <h1>Gallery</h1>
            <p>Offering a gorgeous lawn, RajYou Palace, takes away all your open-air celebration worries.The wedding lawn offers ease of commute to your guests. It covers decor and catering services for your big day. Book RajYog Palace, today to make your celebrations one-of-a-kind.</p>
      </div>
      <div className='b-container'>
           <Box image={image1} alte='images1' button='PYTHON'/>
           <Box image={image2} alte='images2' button='React'/>
           <Box image={image3} alte='images2' button='React'/>
           <Box image={image4} alte='images2' button='React'/>
           <Box image={image5} alte='images2' button='React'/>
           <Box image={image6} alte='images2' button='React'/>
           <Box image={image7} alte='images1' button='PYTHON'/>
           <Box image={image8} alte='images2' button='React'/>
           <Box image={image9} alte='images2' button='React'/>
           <Box image={image10} alte='images2' button='React'/>
           {/* <Box image={image11} alte='images2' button='React'/> */}
      </div>
      {/* <div className='b-container'>
           <Box image={image7} alte='images1' button='PYTHON'/>
           <Box image={image8} alte='images2' button='React'/>
           <Box image={image9} alte='images2' button='React'/>
           <Box image={image10} alte='images2' button='React'/>
           <Box image={image11} alte='images2' button='React'/>
           <Box image={image12} alte='images2' button='React'/>
      </div> */}
        </div>
    )
}
export default Services;