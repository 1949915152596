// import React from 'react';
// import featureimage from '../images/Frame 19.png';
// import featureimage1 from '../images/Frame 20.png'
// function Features(){
//     return (
//         <div id='features'>
//             <div className='features-model'>
//                 <img src={featureimage} alt='feature-image'/>
//                 { <img src={featureimage1} alt='feature-image'/> }
//             </div>
//             <div className='features-text'>
//                 <h2>Services</h2>
//                 {/* <h3>This is <span>Options</span> we have for you</h3> */}
//                 <p>Services Offered by RajYog Palace</p>
//                 <button>View more options</button>
//             </div>
//         </div>
//     )
// }
// export default Features;
import React from 'react';

function Features(){
    return (
        <div id='services'>
            <div className="s-heading">
            {/* <h1>Gallery</h1> */}
            <h2>Services</h2>
            <p>RajYog Palace, allows you to hire a catering team and serve delicious vegetarian and non-vegetarian food which your guests will definitely enjoy. The skilled team of in-house decorators will transform the venue in such a way that no matter which event you are hosting, it will end up looking stunning. The polite staff here will help you host a smooth and memorable event. RajYog Palace reviews will surely help you assess the facilities and service quality maintained here.</p>
      </div>
      <div className='z-container'>
  <div><li className='ul-container' >Upto 1000 Max capacity</li> </div>
  <div><li className='ul-container' >AC Hall with 500 Sitting Capacity</li> </div>
  <div><li className='ul-container' >Fully Air Conditioner Delux and Suite Room</li> </div>
  <div><li className='ul-container' >Catering</li> </div>
  <div><li className='ul-container' >Decoration</li> </div>
  <div><li className='ul-container' >DJ</li> </div>
  {/* <div className="s-heading1"><h2>Gallery</h2></div>
  <p className='details1'>Offering a gorgeous lawn, RajYou Palace, takes away all your open-air celebration worries.The wedding lawn offers ease of commute to your guests. It covers decor and catering services for your big day. Book RajYog Palace, today to make your celebrations one-of-a-kind.</p> */}
</div>



        </div>
    )
}
export default Features;