import React from 'react';
import Navbar from './Navbar';
function Header(){
    return <div id='main'>
         <Navbar/>
         <div className='name'>
            <h1 className='main-head'> Welcome to Rajyog Palace </h1>
            <p className='details'>We are here to help you to provide
             the best service for marriages and other functions.
             RajYog Palace allows you to host a lavish wedding reception or a small function in full life. It has multiple party areas with varying capacities to choose from. The venue serves delicious cuisines that will be loved by your guests. It also has in-house decor team who will take care of the decoration for the venue that day</p>
    
         </div>
         {/* <div className='arrow'></div> */}
    </div>
}
export default Header;