import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer id='footer'>
      <div className="footer-address">
        <h3><FontAwesomeIcon icon={faMapMarker} /> Address</h3>
        <p>Jandaha - Mahua Rd, Hazrat Jandaha<br/> Mansinghpur Bijhrauli, Bihar <br/> Pincode: 844505</p>
      </div>
      <div className="footer-contact">
        <h3> Contact</h3>
        <p><FontAwesomeIcon icon={faPhone} /> Phone: +91 9082474246</p>
        
        <p><FontAwesomeIcon icon={faEnvelope} /> Email: palacerajyog@gmail.com</p>
      </div>
    </footer>
  );
}

export default Footer;
