import React from 'react';
import {Link} from 'react-scroll';
import logo from '../images/logo.png';
//import logo from '../images/logo1.png';
function Navbar(){
    return <div className='nav-color'>
        <nav>
            <Link to='main' className='logo'>
                <img src={logo} alt='logo'/>
            </Link>
            <input className='menu-btn' type='checkbox' id='menu-btn'/>
            <label className='menu-icon' for='menu-btn'>
                <span className='nav-icon'></span>
            </label>
            <ul className='menu'>
                <li><Link to='main' className='active'>Home</Link></li>
                <li><Link to='feature1' >Gallery</Link></li>
                <li><Link to='services' >Services</Link></li>
                <li><Link to='footer' >Contact</Link></li>
                {/* <li><Link to='#'>Menu</Link></li> */}

            </ul>
        </nav>
    </div>
}
export default Navbar;